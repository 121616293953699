

<script>
export default {
  mounted() {
    window.location.replace("/404.html") ;
  },
};
</script>

<style>
</style>